import React from "react"
import Layout from "../components/layout"
import { Router } from "@reach/router"
import { QueryParamProvider } from "use-query-params"
import DFPPreview from "../components/preview/dynamic-front-page"
import PPPreview from "../components/preview/produt-page"
import { PreviewProvider } from "../context/preview-context"
import RecipePagePreview from "../components/preview/recipe-page"
import ArticlePagePreview from "../components/preview/article-page"

const PreviewPage = props => {
  return (
    <PreviewProvider isPreview={true}>
      <Layout>
        <QueryParamProvider>
          <Router basepath="/preview">
            <DFPPreview path="/dynamic-page" />
            <PPPreview path="/product-page" />
            <RecipePagePreview path="/recipe-page" />
            <ArticlePagePreview path="/article-page" />
          </Router>
        </QueryParamProvider>
      </Layout>
    </PreviewProvider>
  )
}

export default PreviewPage
