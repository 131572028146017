import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import DynamicComponent from "../dynamic-components/dynamic-component"
import InfoSection from "./info-section"

const DFPPreview = props => {
  const [pageId, setPageId] = useQueryParam("pageId", StringParam)
  const { loading, error, data } = useQuery(PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      slug: pageId,
    },
  })

  if (loading) return <InfoSection>Loading...</InfoSection>
  if (error) return <InfoSection>Error</InfoSection>
  if (data && data.allDynamicPage.length < 1)
    return <InfoSection>No changes detected...</InfoSection>

  return (
    <DynamicComponent
      components={data.allDynamicPage[0].dynamic_page_components}
    />
  )
}

export default DFPPreview

const PAGE_DATA = gql`
  query($slug: String!) {
    allDynamicPage(
      where: { slug: { current: { eq: $slug } }, _: { is_draft: true } }
    ) {
      slug {
        current
      }
      _id
      dynamic_page_title
      dynamic_page_components {
        ... on ProductTeaserWRecipe {
          _key
          _type
          title
          sub_title
          recipe {
            slug {
              current
            }
            recipe_title
            recipe_image_image {
              video
              image {
                asset {
                  url
                }
              }
            }
          }
          inverted
          refs {
            _id
            product_name
            slug {
              current
            }
            _type
            product_flavor_profile {
              score {
                title
                score
              }
            }
            product_variants {
              variant_type
              variants {
                _key
                variant_info_price
                variant_info_value
              }
            }
            product_collection {
              slug {
                current
              }
            }
            product_label {
              label_title
            }
            product_image {
              asset {
                url
              }
            }
            product_disabled
          }
        }
        ... on HeroSimple {
          _type
          _key
          cta_title
          title
          collection_reference {
            featured_image {
              asset {
                url
              }
            }
            slug {
              current
            }
            color {
              section_background
            }
          }
        }
        ... on HeroBanner {
          _key
          _type
          hero_title
          page_title
          hero_cta {
            cta_text
            cta_link {
              ... on Article {
                _type
                slug {
                  current
                }
              }
              ... on Collection {
                slug {
                  current
                }
                _type
              }
              ... on Product {
                slug {
                  current
                }
                _type
              }
              ... on Recipe {
                slug {
                  current
                }
                _type
              }
            }
          }
          hero_primary_section {
            pitch
            ref {
              ... on Article {
                article_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_image {
                  asset {
                    url
                  }
                }
                article_title
                slug {
                  current
                }
                _type
              }
              ... on Product {
                _type
                slug {
                  current
                }
                product_collection {
                  slug {
                    current
                  }
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    url
                  }
                }
              }
              ... on Collection {
                _id
                _type
                slug {
                  current
                }
                featured_image {
                  asset {
                    url
                  }
                }
                color {
                  section_background
                }
              }
            }
          }
          hero_secondary_section {
            pitch
            ref {
              ... on Article {
                slug {
                  current
                }
                _type
                article_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_title
                article_image {
                  asset {
                    url
                  }
                }
              }
              ... on Product {
                _type
                slug {
                  current
                }
                product_collection {
                  slug {
                    current
                  }
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    url
                  }
                }
              }
              ... on Collection {
                _id
                slug {
                  current
                }
                featured_image {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
        ... on ProductTeaser {
          _type
          _key
          cta_section {
            section_cta_cta {
              cta_link {
                ... on Article {
                  _type
                  slug {
                    current
                  }
                }
                ... on Collection {
                  _type
                  slug {
                    current
                  }
                }
                ... on DynamicPage {
                  _type
                  slug {
                    current
                  }
                }
                ... on Product {
                  _type
                  slug {
                    current
                  }
                }
                ... on Recipe {
                  _type
                  slug {
                    current
                  }
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
          refs {
            _id
            product_name
            slug {
              current
            }
            _type
            product_flavor_profile {
              score {
                title
                score
              }
            }
            product_variants {
              variant_type
              variants {
                _key
                variant_info_price
                variant_info_value
              }
            }
            product_collection {
              slug {
                current
              }
            }
            product_label {
              label_title
            }
            product_image {
              asset {
                url
              }
            }
            product_disabled
          }
        }
        ... on SectionIntroText {
          _key
          _type
          section_link_text
          section_text
          section_title
          section_color {
            section_background
          }
          section_link {
            ... on Article {
              _id
              slug {
                current
              }
              _type
            }
            ... on Product {
              _id
              _type
              slug {
                current
              }
            }
          }
        }
        ... on ArticleTeaser {
          _key
          _type
          article_teaser_references {
            ... on Article {
              _id
              slug {
                current
              }
              title: article_title
              meta: article_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              _type
              image: article_image {
                asset {
                  url
                }
              }
            }
            ... on Recipe {
              _id
              slug {
                current
              }
              _type
              title: recipe_title
              meta: recipe_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              image: recipe_image_image {
                video
                image {
                  asset {
                    url
                  }
                }
              }
            }
          }
          cta_section {
            section_cta_cta {
              cta_link {
                ... on Article {
                  slug {
                    current
                  }
                  _type
                }
                ... on ArticleType {
                  slug {
                    current
                  }
                  _type
                }
                ... on Collection {
                  slug {
                    current
                  }
                  _type
                }
                ... on DynamicPage {
                  slug {
                    current
                  }
                  _type
                }
                ... on Product {
                  slug {
                    current
                  }
                  _type
                }
                ... on Recipe {
                  slug {
                    current
                  }
                  _type
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
        }
        ... on PageTitle {
          _key
          _type
          page_title_header
          page_title_intro
          topics {
            topics {
              article_type_title
              slug {
                current
              }
            }
          }
        }
        ... on AllTopics {
          _key
          _type
        }
        ... on CollectionTeaser {
          _key
          _type
          collection_references {
            color {
              section_background
            }
            featured_image {
              asset {
                url
              }
            }
            slug {
              current
            }
            pitch
            collection_title
            _id
          }
        }
      }
    }
  }
`
