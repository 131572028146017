import React from "react"
import { gql } from "apollo-boost"
import { useQueryParam, StringParam } from "use-query-params"
import { useQuery } from "@apollo/react-hooks"
import InfoSection from "./info-section"
import ProductPageComponents from "../product/product-page-components"

const ProductPagePreview = props => {
  const [pageId, setPageId] = useQueryParam("pageId", StringParam)
  const { loading, error, data } = useQuery(PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      _id: pageId,
    },
  })

  if (loading) return <InfoSection>Loading...</InfoSection>
  if (error) return <InfoSection>Error</InfoSection>

  const dataObject = {
    sanityProduct: {
      _rawProductDescription: data.Product.product_descriptionRaw,
      _rawProductHowToServe: data.Product.product_how_to_serveRaw,
      ...data.Product,
    },
  }

  return <ProductPageComponents data={dataObject} />
}

export default ProductPagePreview

const PAGE_DATA = gql`
  query($_id: ID!) {
    Product(id: $_id) {
      product_how_to_serveRaw
      product_descriptionRaw
      product_meta_data {
        refrigerate
        product_related_product {
          slug {
            current
          }
          product_collection {
            slug {
              current
            }
          }
          product_label {
            label_title
          }
          product_name
          product_image {
            asset {
              url
            }
          }
        }
      }
      product_disabled
      allergens
      ingredients
      informative_label {
        value
        key
      }
      product_variants {
        variants {
          _key
          variant_info_price
          variant_info_value
        }
        variant_type
      }
      product_name

      product_preview_color {
        section_background
      }
      product_image {
        asset {
          url
        }
      }
      image_rotate
      product_label {
        label_title
      }
      product_flavor_profile {
        score {
          title_ref {
            title
          }
          score
        }
      }
      product_collection {
        collection_title
      }
      product_article_teaser {
        title
        intro_text
        references {
          ... on Article {
            _id
            slug {
              current
            }
            title: article_title
            meta: article_meta_data {
              article_type_ref {
                article_type_title
              }
            }
            _type
            image: article_image {
              asset {
                url
              }
            }
          }
          ... on Recipe {
            _id
            slug {
              current
            }
            _type
            title: recipe_title
            meta: recipe_meta_data {
              article_type_ref {
                article_type_title
              }
            }
            image: recipe_image_image {
              video
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
