import React from "react"
import { gql } from "apollo-boost"
import { useQueryParam, StringParam } from "use-query-params"
import { useQuery } from "@apollo/react-hooks"
import InfoSection from "./info-section"
import ArticleComponents from "../content-pages/article-components"

const ArticlePagePreview = props => {
  console.log("preview")
  const [pageId, setPageId] = useQueryParam("pageId", StringParam)
  const { loading, error, data } = useQuery(PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      _id: pageId,
    },
  })

  if (loading) return <InfoSection>Loading...</InfoSection>
  if (error) return <InfoSection>Error</InfoSection>

  const dataObject = {
    sanityArticle: {
      ...data.Article,
    },
  }

  return <ArticleComponents data={dataObject} /> ////<ProductPageComponents data={data} />
}

export default ArticlePagePreview

const PAGE_DATA = gql`
  query($_id: ID!) {
    Article(id: $_id) {
      article_related_items {
        ... on Article {
          _id
          _type
          slug {
            current
          }
          article_title
        }
        ... on Recipe {
          _id
          _type
          recipe_title
          slug {
            current
          }
        }
        ... on Product {
          _id
          product_name
          _type
          product_image {
            asset {
              url
            }
          }
          product_variants {
            variants {
              variant_info_price
            }
          }
          slug {
            current
          }
          product_collection {
            slug {
              current
            }
          }
        }
      }
      article_content {
        ... on ArticleSection {
          _key
          _type
          section_contentRaw
          section_aside
          section_title
        }
        ... on Image {
          _key
          _type
          asset {
            url
          }
        }
      }
      article_image {
        asset {
          url
        }
      }
      article_intro
      article_meta_data {
        article_type_ref {
          article_type_title
        }
      }
      article_title
    }
  }
`
