import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { IoMdCheckmarkCircle } from "react-icons/io"

export default () => {
  const { register, handleSubmit, errors } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const submit = async data => {
    setIsLoading(true)

    const res = await fetch(`${process.env.GATSBY_API_BASE_URL}api/Subscribe`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
    setIsComplete(true)
  }

  const button = () => {
    if ((!isLoading && !isComplete) || (isLoading && !isComplete)) {
      return (
        <button
          disabled={isLoading && !isComplete}
          className="newsletter-box__submit"
          type="submit"
        >
          {!isLoading && !isComplete && <>Subscribe</>}
          {isLoading && !isComplete && (
            <div className="complete">
              <div className="loader is-loading"></div>
            </div>
          )}
        </button>
      )
    } else if (isLoading && isComplete) {
      return (
        <div className="complete">
          <IoMdCheckmarkCircle className="complete__icon" />
        </div>
      )
    }
  }

  return (
    <div className="newsletter-box">
      <h4 className="h4">
        Want to receive news of products, events and delicious recipes?
      </h4>
      <form className="newsletter-box__form" onSubmit={handleSubmit(submit)}>
        <input
          name="emailAddress"
          className="newsletter-box__email"
          type="email"
          required
          placeholder="Enter email"
          ref={register({ required: true })}
        />
        <input
          name="name"
          className="newsletter-box__name"
          type="text"
          placeholder="Enter name"
          ref={register}
        />
        {errors.email && (
          <p className="form__input-error">Email is required!</p>
        )}
        {button()}
      </form>
    </div>
  )
}
