import React, { useContext } from "react"
import SectionColumns from "../elements/section-columns"
import {
  VeraImageWide,
  VeraImage,
  VeraImageSquare,
} from "../elements/vera-image"
import ContentLoop from "./content-loop"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Article from "../dynamic-components/article/article"
import HorizontalScrollable from "../hoc/horizontal-scrollable"
import AsideTeaser from "./aside-teaser"
import SocialSharing from "../elements/social-sharing"
import NewsLetterBox from "../elements/news-letter-box"
import { Link } from "gatsby"
import { linkResolver } from "../../util"
import useRandomArray from "../../hooks/use-random-array"
import { PreviewContext } from "../../context/preview-context"

const ArticleComponents = ({ data }) => {
  const { sanityArticle, allSanityArticle, allSanityArticleType } = data
  const { isPreview } = useContext(PreviewContext)
  const articleTypes = allSanityArticleType ? allSanityArticleType.nodes : []
  const articles = allSanityArticle ? allSanityArticle.nodes : []
  const otherTopics = useRandomArray(articleTypes, 2)
  const relatedArticles = useRandomArray(articles, 3)

  return (
    <>
      <SectionColumns
        isGapless
        wide
        isDesktop
        sectionClasses="has-padding-top-0--mobile has-padding-none--tablet"
      >
        <div className="column is-half-desktop has-no-margin">
          {sanityArticle.article_image && sanityArticle.article_image.asset && (
            <VeraImageWide
              asset={sanityArticle.article_image.asset}
              className="article-page__mobile-image"
            />
          )}
          {sanityArticle.article_image && sanityArticle.article_image.asset && (
            <VeraImageSquare
              asset={sanityArticle.article_image.asset}
              className="article-page__desktop-image"
            />
          )}
          {sanityArticle.article_image && sanityArticle.article_image.asset && (
            <VeraImage
              asset={sanityArticle.article_image.asset}
              className="article-page__widescreen-image"
            />
          )}
        </div>
        <div className="column is-half-desktop">
          <div className="article-page__intro">
            {sanityArticle.article_meta_data &&
              sanityArticle.article_meta_data.article_type_ref &&
              sanityArticle.article_meta_data.article_type_ref
                .article_type_title && (
                <div className="article-label">
                  {
                    sanityArticle.article_meta_data.article_type_ref
                      .article_type_title
                  }
                </div>
              )}
            <h1 style={{ margin: "20px 0" }} className="article-page__title">
              {sanityArticle.article_title}
            </h1>
            <span className="intro-text">{sanityArticle.article_intro}</span>
          </div>
        </div>
      </SectionColumns>
      {/* <SectionColumns>
        <div className="column">
          <HorizontalScrollable
            className="product-page__menu"
            childClassName="product-page__menu-item"
          >
            {sanityArticle.article_content.map(content => {
              if (content.section_title) {
                return (
                  <AnchorLink
                    offset="100"
                    className="anchor-link"
                    key={content.section_title.replace(/\s/g, "")}
                    href={`#${content.section_title.replace(/\s/g, "")}`}
                  >
                    {content.section_title}
                  </AnchorLink>
                )
              }
            })}
          </HorizontalScrollable>
        </div>
      </SectionColumns> */}
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-9">
              {sanityArticle.article_content && (
                <ContentLoop
                  breadCrumbSection={<Link to="/explorer">Articles</Link>}
                  breadCrumbTitle={sanityArticle.article_title}
                  content={sanityArticle.article_content}
                />
              )}

              <div className="columns">
                <div className="column is-8-tablet is-offset-4-tablet is-9-widescreen is-offset-3-widescreen">
                  <div className="article-page__mini-divider"></div>
                  <div className="article-page__info-box">
                    <div className="sharing">
                      <span
                        style={{
                          color: "rgba(0, 0, 0, 0.6)",
                          textTransform: "uppercase",
                          fontSize: "0.75rem",
                          marginRight: "15px",
                        }}
                      >
                        OTHER TAGS:
                      </span>
                      <div className="popular-topics">
                        {otherTopics.map((topic, i) => (
                          <Link
                            key={`article_type__${i}`}
                            to={linkResolver({
                              _type: "article_type",
                              slug: topic.slug.current,
                            })}
                            className="popular-topics__link-btn"
                          >
                            {topic.article_type_title}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="sharing">
                      <span>SHARE:</span>
                      <SocialSharing simple isRecipe />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-8-tablet is-offset-4-tablet is-9-widescreen is-offset-3-widescreen">
                  <div className="article-page__newsletter">
                    <NewsLetterBox />
                  </div>
                </div>
              </div>
            </div>
            {sanityArticle.article_related_items &&
              sanityArticle.article_related_items.length > 0 && (
                <div className="column">
                  <AsideTeaser teaser={sanityArticle.article_related_items} />
                </div>
              )}
          </div>
        </div>
      </section>
      <SectionColumns
        isMultiline
        isCentered
        sectionClasses="has-background-light"
      >
        <div className="column is-12 has-text-centered">
          <h2 className="h3">Related</h2>
        </div>
        {relatedArticles.map(article => (
          <div key={article._id} className="column is-4">
            <Article {...article} />
          </div>
        ))}
      </SectionColumns>

      {/* <SectionColumns>
        <div className="column is-3">
          <BreadCrumb section="Articles" title={sanityArticle.article_title} />
        </div>
        <div className="column is-half">
          <ContentLoop content={sanityArticle.article_content} />
        </div>
      </SectionColumns> */}
    </>
  )
}

export default ArticleComponents
