import React from "react"
import { gql } from "apollo-boost"
import { useQueryParam, StringParam } from "use-query-params"
import { useQuery } from "@apollo/react-hooks"
import InfoSection from "./info-section"
import RecipeComponents from "../content-pages/recipe-components"

const RecipePagePreview = props => {
  const [pageId, setPageId] = useQueryParam("pageId", StringParam)
  const { loading, error, data } = useQuery(PAGE_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      _id: pageId,
    },
  })

  if (loading) return <InfoSection>Loading...</InfoSection>
  if (error) return <InfoSection>Error</InfoSection>

  const dataObject = {
    sanityRecipe: {
      ...data.Recipe,
    },
  }

  return <RecipeComponents data={dataObject} /> ////<ProductPageComponents data={data} />
}

export default RecipePagePreview

const PAGE_DATA = gql`
  query($_id: ID!) {
    Recipe(id: $_id) {
      _type
      _key
      recipe_title
      recipe_content {
        ... on ArticleSection {
          _type
          _key
          section_contentRaw
          section_aside
          section_title
        }
        ... on Image {
          _key
          _type
          asset {
            url
          }
        }
      }
      recipe_image_image {
        image {
          asset {
            url
          }
        }
        video
      }
      slug {
        current
      }
      article_related_items {
        ... on Article {
          _id
          _type
          slug {
            current
          }
          article_title
        }
        ... on Recipe {
          _id
          _type
          recipe_title
          slug {
            current
          }
        }
        ... on Product {
          _id
          product_name
          _type
          product_image {
            asset {
              url
            }
          }
          product_variants {
            variants {
              variant_info_price
            }
          }
          slug {
            current
          }
          product_collection {
            slug {
              current
            }
          }
        }
      }
    }
  }
`
