import React, { useContext } from "react"
import SectionColumns from "../elements/section-columns"
import BreadCrumb from "../elements/breadcrumb"
import BlockContent from "@sanity/block-content-to-react"
import ContentLoop from "./content-loop"
import { PreviewContext } from "../../context/preview-context"
import SocialSharing from "../elements/social-sharing"
import AsideTeaser from "./aside-teaser"
import useRandomArray from "../../hooks/use-random-array"
import { linkResolver } from "../../util"
import { Link } from "gatsby"
import Article from "../dynamic-components/article/article"

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      if (!mark?.reference) {
        return <>{children}</>
      }

      const { slug, _type } = mark.reference
      const href = linkResolver({
        slug: slug.current,
        _type,
        productSlug: mark.reference.product_collection
          ? mark.reference.product_collection.slug.current
          : "",
      })

      return <a href={href}>{children}</a>
    },
  },
}

const RecipeComponents = ({ data }) => {
  const { sanityRecipe, allSanityArticleType, allSanityRecipe } = data
  const { isPreview } = useContext(PreviewContext)
  const articleTypes = allSanityArticleType ? allSanityArticleType.nodes : []
  const recipes = allSanityRecipe ? allSanityRecipe.nodes : []
  const otherTopics = useRandomArray(articleTypes, 2)
  const relatedRecipes = useRandomArray(recipes, 3)
  return (
    <>
      <SectionColumns isCentered>
        <div className="column is-half">
          <h1 className="article-page__title">{sanityRecipe.recipe_title}</h1>
        </div>
      </SectionColumns>
      <SectionColumns sectionClasses="has-padding-top-0--mobile">
        <div
          className="column is-3 is-hidden-mobile"
          style={{ paddingTop: "2.5rem" }}
        >
          <SocialSharing
            style={{ position: "absolute", top: "-20px" }}
            isRecipe
          />
          <BreadCrumb
            space
            section={<Link to="/recipe/">Recipes</Link>}
            title={sanityRecipe.recipe_title}
          />
        </div>
        <div className="column is-3 rich-text" style={{ paddingTop: "2.5rem" }}>
          {sanityRecipe.recipe_content && sanityRecipe.recipe_content[0] && (
            <>
              {isPreview ? (
                <BlockContent
                  serializers={serializers}
                  blocks={sanityRecipe.recipe_content[0].section_contentRaw}
                />
              ) : (
                <BlockContent
                  serializers={serializers}
                  blocks={sanityRecipe.recipe_content[0]._rawSectionContent}
                />
              )}
            </>
          )}
        </div>
        <div className="column is-5 rich-text recipe__ingredients-wrapper">
          <div className="recipe__ingredients">
            <div className="label-wrapper">
              <div className="article-label">Recipe</div>
            </div>
            {sanityRecipe.recipe_content && sanityRecipe.recipe_content[1] && (
              <>
                {isPreview ? (
                  <BlockContent
                    serializers={serializers}
                    blocks={sanityRecipe.recipe_content[1].section_contentRaw}
                  />
                ) : (
                  <BlockContent
                    serializers={serializers}
                    blocks={sanityRecipe.recipe_content[1]._rawSectionContent}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </SectionColumns>
      <SectionColumns>
        <div className="column is-8 is-offset-1-widescreen">
          <div className="columns">
            <div className="column">
              <ContentLoop
                ignoreBreadcrumb
                content={sanityRecipe.recipe_content}
                recipeImage={sanityRecipe.recipe_image_image}
                isRecipe
              />
              <div className="columns">
                <div className="column is-4-tablet is-3-widescreen"></div>
                <div className="column">
                  <div className="article-page__mini-divider"></div>
                  <div
                    className="article-page__info-box"
                    style={{ marginRight: 0 }}
                  >
                    <div className="sharing">
                      <span
                        style={{
                          color: "rgba(0, 0, 0, 0.6)",
                          textTransform: "uppercase",
                          fontSize: "0.75rem",
                          marginRight: "15px",
                        }}
                      >
                        OTHER TAGS:
                      </span>
                      <div className="popular-topics">
                        {otherTopics.map((topic, i) => (
                          <Link
                            key={`article_type__${i}`}
                            to={linkResolver({
                              _type: "article_type",
                              slug: topic.slug.current,
                            })}
                            className="popular-topics__link-btn"
                          >
                            {topic.article_type_title}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="sharing">
                      <span>SHARE:</span>
                      <SocialSharing simple isRecipe />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sanityRecipe.article_related_items &&
          sanityRecipe.article_related_items.length > 0 && (
            <div className="column">
              <AsideTeaser teaser={sanityRecipe.article_related_items} />
            </div>
          )}
      </SectionColumns>
      <SectionColumns
        isMultiline
        isCentered
        sectionClasses="has-background-light"
      >
        <div className="column is-12 has-text-centered">
          <h2 className="h3">Related</h2>
        </div>
        {relatedRecipes.map(article => (
          <div key={article._id} className="column is-4">
            <Article {...article} />
          </div>
        ))}
      </SectionColumns>
    </>
  )
}

export default RecipeComponents
